import styled from 'styled-components';

export const TopBlock = styled.div`
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.white};
`;

export const ContentContainer = styled.div`
  background-color: ${({ theme }) => theme.lightGrey};
  padding: 0 0.75rem;

  ${({ theme }) => theme.onDesktop} {
    padding: 0 3rem;
  }
`;

export const ContentCard = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.1);
  max-width: 80ch;
  margin: 0 auto;
  padding: 2rem 1.25rem;
  transform: translateY(-1.5rem);

  ${({ theme }) => theme.onDesktop} {
    padding: 4rem;
    transform: translateY(-3rem);
  }

  h1 {
    color: ${({ theme }) => theme.primary};
    margin-top: 0;
  }

  * {
    max-width: 100%;
  }
`;

export const ImageContainer = styled.div`
  line-height: 0;

  .gatsby-image-wrapper {
    max-height: 60vh;
  }
`;
