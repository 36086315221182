import React from 'react';
import {
  array,
  string,
  node,
  func,
  oneOfType,
  object,
  shape,
} from 'prop-types';
import { kebabCase } from 'lodash';
import PageHelmet from 'components/Helmets/PageHelmet';
import PageTitle from 'components/PageTitle';
import { graphql, Link } from 'gatsby';
import {
  TopBlock,
  ContentContainer,
  ContentCard,
  ImageContainer,
} from 'style/components/blog-post-page';
import Content, { HTMLContent } from 'components/Content';
import PreviewCompatibleImage from 'components/PreviewCompatibleImage';

export const BlogPostTemplate = ({
  content,
  contentComponent,
  image,
  imageAlt,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section>
      {helmet || ''}
      <TopBlock>
        <ImageContainer>
          <PreviewCompatibleImage imageInfo={image} alt={imageAlt} />
        </ImageContainer>
      </TopBlock>

      <ContentContainer>
        <ContentCard>
          <PageTitle noTopMargin>{title}</PageTitle>
          <PostContent content={content} />
          {tags && tags.length ? (
            <div style={{ marginTop: `4rem` }}>
              <h4>Tags</h4>
              <ul>
                {tags.map((tag) => (
                  <li key={`${tag}tag`}>
                    <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </ContentCard>
      </ContentContainer>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: node.isRequired,
  contentComponent: func,
  title: string,
  image: oneOfType([string, object]),
  imageAlt: string,
  helmet: object,
  tags: array,
};

BlogPostTemplate.defaultProps = {
  contentComponent: undefined,
  title: ``,
  helmet: undefined,
  tags: undefined,
  image: ``,
  imageAlt: ``,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <BlogPostTemplate
      content={post.html}
      contentComponent={HTMLContent}
      helmet={(
        <PageHelmet
          pageTitle={post.frontmatter.title}
          description={post.frontmatter.description}
          path={post.fields.slug}
        />
      )}
      {...post.frontmatter}
    />
  );
};

BlogPost.propTypes = {
  data: shape({
    markdownRemark: object,
  }).isRequired,
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 1700, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageAlt
        imageCredit {
          author {
            name
            url
            site
            siteURL
          }
          licence
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
